import { motion } from "framer-motion";

export function Example() {
  return (
    <motion.div
      style={{
        width: 150,
        height: 150,
        borderRadius: 30,
        backgroundColor: "#fff"
      }}
      animate={{ rotate: 45, scale: 1.5 }}
      transition={{ ease: "anticipate", duration: 2, delay: 0.3 }}
    />
  );
}
